import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useNavigate } from "react-router-dom";

import { TrashIcon, UserIcon, EyeIcon } from "@heroicons/react/24/solid";

import caret from "../../Images/caret-down.png";
import className from "classnames";

import { IconButton, Tooltip } from "@material-tailwind/react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import {
  ConvertTime,
  DatePicker,
  InputSearch,
  ResetButton,
  SchemeDropDown,
  SchemeForDropDown,
  SchemeNameDropDown,
  handleUnAuthorized,
} from "../../hook/handleUnauthorized";

const TransactionsTable = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("asc");
  const [open2, setOpen2] = useState(false);
  const [donationList, setDonationList] = useState([]);
  const [item, setItem] = useState({});
  const [search, setSearch] = useState({});
  const [edit, setEdit] = useState(false);
  const [sort, setSort] = useState({ createdAtUpArrow: true });
  const [sortingState, setSortingState] = useState({
    sortBy: "createdAt",
    sortType: "desc",
  });
  const navigate = useNavigate();

  const itemsPerPage = 5;

  const handleMakeAdmin = (data) => {
    // Handle making member admin
    setEdit(true);
    setItem(data);
    setOpen2(true);
  };

  const handleSort = (e, sortType, state) => {
    setSortingState({ sortBy: e.target.name, sortType });
    setSort({ [state]: true });
  };

  // const handleDownlaodReceipt = async (guid) => {
  //   try {
  //     await axios.get(
  //       `${process.env.REACT_APP_API_URL}/api/razorpay/recipt/${guid}`
  //     );
  //   } catch (error) {}
  // };
  const handleDltMember = (id) => {
    // Handle deleting member
  };
  const members = [];
  const getDonationList = async () => {
    const token = localStorage.getItem("adminToken") || "";
    if (token) {
      let query = {
        schemeName: search.schemeName,
        endDate: search.endDate,
        startDate: search.startDate,
        keyword: search.keyword,
        schemeFor: search.schemeFor,
      };
      try {
        const data = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/transactions`,
          { params: query, headers: { Authorization: token } }
        );

        if (data.data.success) {
          setDonationList(data.data.transactions);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      navigate("/login");
    }
  };
  useEffect(() => {
    getDonationList();
  }, [search, sortingState]);

  return (
    <>
      <div className="bg-white shadow px-0 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">
        <div className="mb-4 flex justify-end">
          {/* <ResetButton search={search} setSearch={setSearch} />
          <DatePicker search={search} setSearch={setSearch} />
          <InputSearch
            search={search}
            setSearch={setSearch}
            msg={"Search Donar..."}
          /> */}
          {/* <SchemeForDropDown search={search} setSearch={setSearch} /> */}
        </div>
        <table className="w-full whitespace-nowrap">
          <thead>
            <tr className="h-16 w-full text-sm leading-none text-gray-800">
              <th className="font-normal text-left pl-4">S.No.</th>
              <th className="font-normal text-left pl-4">
                Donor Name
                <button
                  className="ml-1"
                  style={{
                    width: "1.5rem",
                    height: "1.2rem",
                    backgroundColor: "white",
                  }}
                >
                  <img
                    name="donarName"
                    src={caret}
                    alt=""
                    className={className("w-2.5 rotate-180 ", {
                      "opacity-20": sort.donarNameUpArrow,
                    })}
                    onClick={(e) => handleSort(e, "asc", "donarNameUpArrow")}
                  />
                  <img
                    name="donarName"
                    src={caret}
                    alt=""
                    className={className("w-2.5", {
                      "opacity-20": sort.donarNameDownArrow,
                    })}
                    onClick={(e) => handleSort(e, "desc", "donarNameDownArrow")}
                  />
                </button>
              </th>

              <th className="font-normal text-left pl-12">
                Date
                <button
                  className="ml-1"
                  style={{
                    width: "1.5rem",
                    height: "1.2rem",
                    backgroundColor: "white",
                  }}
                >
                  <img
                    name="createdAt"
                    src={caret}
                    alt=""
                    className={className("w-2.5 rotate-180 ", {
                      "opacity-20": sort.createdAtUpArrow,
                    })}
                    onClick={(e) => handleSort(e, "asc", "createdAtUpArrow")}
                  />
                  <img
                    name="createdAt"
                    src={caret}
                    alt=""
                    className={className("w-2.5", {
                      "opacity-20": sort.createdAtDownArrow,
                    })}
                    onClick={(e) => handleSort(e, "desc", "createdAtDownArrow")}
                  />
                </button>
              </th>
              <th className="font-normal text-left pl-12">
              Status
                <button
                  className="ml-1"
                  style={{
                    width: "1.5rem",
                    height: "1.2rem",
                    backgroundColor: "white",
                  }}
                >
                
                </button>
              </th>
              <th className="font-normal text-left pl-16">
                Amount ( In INR)
                <button
                  className="ml-1"
                  style={{
                    width: "1.5rem",
                    height: "1.2rem",
                    backgroundColor: "white",
                  }}
                >
                  <img
                    name="amount"
                    src={caret}
                    alt=""
                    className={className("w-2.5 rotate-180 ", {
                      "opacity-20": sort.amountUpArrow,
                    })}
                    onClick={(e) => handleSort(e, "asc", "amountUpArrow")}
                  />
                  <img
                    name="amount"
                    src={caret}
                    alt=""
                    className={className("w-2.5", {
                      "opacity-20": sort.amountDownArrow,
                    })}
                    onClick={(e) => handleSort(e, "desc", "amountDownArrow")}
                  />
                </button>
              </th>

              <th className="font-normal text-left pl-16">
                Payment reference id
                <button
                  className="ml-1"
                  style={{
                    width: "1.5rem",
                    height: "1.2rem",
                    backgroundColor: "white",
                  }}
                >
                  <img
                    name="paymentReferenceId"
                    src={caret}
                    alt=""
                    className={className("w-2.5 rotate-180 ", {
                      "opacity-20": sort.paymentReferenceIdUpArrow,
                    })}
                    onClick={(e) =>
                      handleSort(e, "asc", "paymentReferenceIdUpArrow")
                    }
                  />
                  <img
                    name="paymentReferenceId"
                    src={caret}
                    alt=""
                    className={className("w-2.5", {
                      "opacity-20": sort.paymentReferenceIdDownArrow,
                    })}
                    onClick={(e) =>
                      handleSort(e, "desc", "paymentReferenceIdDownArrow")
                    }
                  />
                </button>
              </th>
              <th className="font-normal text-left pl-16">Address</th>
              <th className="font-normal text-left pl-16">Receipt</th>
            </tr>
          </thead>
          <tbody className="w-full">
            {donationList.map((item, index) => (
              <tr
                key={index}
                className="h-20 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100"
              >
                <td className="pl-4 cursor-pointer">
                  <div className="flex items-center">
                    <div className="w-10 h-10">{index + 1}</div>
                  </div>
                </td>
                <td className="">
                  {item?.first_name +
                    (item.middleName ? ` ${item.middleName}` : "") +
                    " " +
                    item?.last_name}
                </td>

                <td className="pl-12">{ConvertTime(item.createdAt)}</td>

                <td
                  className={
                    item.status == "pending"
                      ? "text-danger"
                      : "text-success"
                  }
                >
                  {item.status}
                </td>
                <td className="pl-12">{item.amount}</td>

                <td className="pl-12">{item.paymentId}</td>
                <td className="pl-12">
                  <div className="flex items-center">
                    <div className="">
                      {item.address}, {item.city}, {item.state} ${item.country}
                    </div>
                  </div>
                </td>
                <td className="pl-12">
                  {item.paymentId === "notCaptured" ? (
                    ""
                  ) : (
                    <a
                      href={`${process.env.REACT_APP_API_URL}/api/razorpay/recipt/${item.guid}`}
                    >
                      <i class="fa fa-solid fa-file-download"></i>
                    </a>
                  )}
                </td>

                {/* <td className="pl-16">
                  <p className="text-sm font-medium leading-none text-gray-800">
                    {member.createdAt}
                  </p>
                </td> 
                <Tooltip content="Delete member">
                <IconButton
                variant="text"
                color="blue-gray"
                onClick={() => handleDltMember(member.id)}
                >
                <ViewfinderCircleIcon className="h-5 w-5" />
                </IconButton>
              </Tooltip>*/}
                {/* <td className="pl-7 2xl:px- 0 ml-5">
                  <Tooltip content="Edit">
                    <IconButton
                      variant="text"
                      color="blue-gray"
                      onClick={() => handleMakeAdmin(item)}
                    >
                      <EyeIcon className="h-4 w-4" />
                    </IconButton>
                  </Tooltip>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
        {donationList.length === 0 && (
          <div className="border p-2" style={{ textAlign: "center" }}>
            No Data Found.
          </div>
        )}

        {/* <div className="flex justify-center mt-5">
          <div className="flex">
            <p className="text-[#452a72] pt-3">Total Pages -</p>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                className={`px-3 py-1 text-sm font-medium mx-1 rounded-md focus:outline-none ${currentPage === index + 1
                  ? 'bg-[#452a72] text-white'
                  : 'text-[#452a72]'
                  }`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div> */}
      </div>
    </>
  );
};

export default TransactionsTable;
