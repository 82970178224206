import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";

import TextEditor from "../Pages/Text";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { handleUnAuthorized } from "../hook/handleUnauthorized";
import ReactQuill from "react-quill";
import "../generosity/css/AddBlog.css";
import AddCat from "./Components/AddCat";
import _ from "lodash";
const CategoriesComp = ({ category, handleRemove }) => {
  console.log("form category comp ", category);
  return (
    <div
      style={{
        display: "inline-block",
      }}
      className=" py-1 px-2 mb-1 bg-blue-50 mx-2  "
    >
      {category?.key}{" "}
      <span
        onClick={() => {
          handleRemove(category?.guid);
        }}
        className="bg-blue btn mb-1 p-0 fs-4"
        style={{
          transform: "rotate(45deg)",
        }}
      >
        +
      </span>
    </div>
  );
};
function BlogForm({ isEdit, editBlogDetail }) {
  const navigate = useNavigate();
  const [category, setCategory] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(
    isEdit ? editBlogDetail?.category : []
  );
  const [blogDetail, setBlogDetail] = useState({
    title: "",
    description: "",
    image: "",
    authorName: "",
    authorImage: "",
    shortDescription: "",
    ...editBlogDetail,
  });
  const selectRef = useRef();
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const adminToken = localStorage.getItem("adminToken") || "";

    const form = new FormData();
    if (blogDetail.image) form.append("image", blogDetail.image);
    if (blogDetail.title) form.append("title", blogDetail.title);
    if (blogDetail.description)
      form.append("description", blogDetail.description);
    if (selectedCategories)
      form.append("category", JSON.stringify(selectedCategories));
    if (blogDetail.authorImage)
      form.append("authorImage", blogDetail.authorImage);
    if (blogDetail.shortDescription)
      form.append("shortDescription", blogDetail.shortDescription);
    if (blogDetail.authorName) form.append("authorName", blogDetail.authorName);
    console.log("blog form data post", form);
    if (isEdit) {
      try {
        const data = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/blog/${editBlogDetail?.guid}`,
          form,
          { headers: { Authorization: adminToken } }
        );
        if (data.data.success) {
          // setBlogDetail({
          //     title: '',
          //     description: '',
          //     image: ''
          // })
          navigate("/admin/blog");
          setTimeout(() => {
            toast.success(data.data.msg, {
              position: toast.POSITION.TOP_RIGHT,
              className: "toast-success",
            });
          }, 500);
        } else {
          handleUnAuthorized(data.data.msg, navigate);
        }
      } catch (error) {
        toast.error("something went wrong");
        console.error("from blog form edit==", error);
      }
    } else {
      const data = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/add-blog-manager`,
        form,
        { headers: { Authorization: adminToken } }
      );
      if (data.data.success) {
        // setBlogDetail({
        //     title: '',
        //     description: '',
        //     image: ''
        // })
        navigate("/admin/blog");
        setTimeout(() => {
          toast.success(data.data.msg, {
            position: toast.POSITION.TOP_RIGHT,
            className: "toast-success",
          });
        }, 500);
      } else {
        handleUnAuthorized(data.data.msg, navigate);
      }
    }
  };

  useEffect(() => {
    const adminToken = localStorage.getItem("adminToken") || "";
    if (!adminToken) navigate("/admin-login");
  }, []);
  useEffect(() => {
    async function fetchData() {
      try {
        const data = await axios.get(
          process.env.REACT_APP_API_URL + "/api/category"
        );

        setCategory(data.data.cate);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  const handleImageChange = (e) =>
    setBlogDetail({ ...blogDetail, [e.target.name]: e.target.files[0] });
  const handleChange = (e) =>
    setBlogDetail({ ...blogDetail, [e.target.name]: e.target.value });
  function handleRemove(cate) {
    const categories = _.remove(selectedCategories, (c) => cate !== c);
    setSelectedCategories([...categories]);
  }
  useEffect(() => {
    console.log("useeffect blog detailon change", blogDetail);
  }, [blogDetail]);

  return (
    <>
      <div className="px-0 py-0 zIndex-1000 ">
        <div className="flex flex-no-wrap items-start">
          <div
            className="w-full "
            style={{
              minHeight: "600px",
            }}
          >
            <div className="px-2">
              <form onSubmit={handleFormSubmit}>
                <div className="bg-white rounded shadow mt-3 py-7">
                  <div className=" px-7">
                    <p className="text-xl font-semibold leading-tight text-gray-800 ">
                      {isEdit ? "Edit" : "Add"} Blog
                    </p>
                    <div
                      className="row "
                      //  className="grid w-50 grid-cols-1 lg:grid-cols-1 md:grid-cols-1 gap-7 mt-7 "
                    >
                      <div className="col-sm-6 my-4">
                        <p className="text-left font-medium leading-none text-gray-800">
                          Title<span className="text-red-600">*</span>
                        </p>
                        <input
                          type="text"
                          className="w-full p-3 mt-4 border border-gray-300 rounded outline-none focus:bg-gray-50"
                          value={blogDetail?.title}
                          name="title"
                          placeholder="Title"
                          onChange={(e) => handleChange(e)}
                          required
                        />
                        {/* <p className="mt-3 text-xs leading-3 text-gray-600">
                                                Set a simple and precise name
                                            </p> */}
                      </div>
                      <div className="col-sm-6 my-4">
                        <p className="text-left font-medium leading-none text-gray-800">
                          Author Name<span className="text-red-600">*</span>
                        </p>
                        <input
                          type="text"
                          className="w-full p-3 mt-4 border border-gray-300 rounded outline-none focus:bg-gray-50"
                          value={blogDetail?.authorName}
                          name="authorName"
                          placeholder="Author Name"
                          onChange={(e) => handleChange(e)}
                          required
                        />
                        {/* <p className="mt-3 text-xs leading-3 text-gray-600">
                                                Set a simple and precise name
                                            </p> */}
                      </div>
                      <div className="col-sm-12 my-4">
                        <p className="text-left font-medium leading-none text-gray-800">
                          Short Description
                          <span className="text-red-600">*</span>
                        </p>
                        <input
                          type="text"
                          className="w-full p-3 mt-4 border border-gray-300 rounded outline-none focus:bg-gray-50"
                          value={blogDetail?.shortDescription}
                          name="shortDescription"
                          placeholder="Short Description"
                          onChange={(e) => handleChange(e)}
                          required
                        />
                        {/* <p className="mt-3 text-xs leading-3 text-gray-600">
                                                Set a simple and precise name
                                            </p> */}
                      </div>
                      <div className="col-sm-6 ">
                        <p className="text-left font-medium leading-none text-gray-800 ">
                          Author Image
                        </p>
                        <input
                          type="file"
                          className="w-full p-3 mt-4 border border-gray-300 rounded outline-none focus:bg-gray-50"
                          name="authorImage"
                          onChange={(e) => handleImageChange(e)}
                        />
                        {/* <p className="mt-3 text-xs leading-3 text-gray-600">
                                                Set a simple and precise name
                                            </p> */}
                      </div>
                      <div className="col-sm-6">
                        <p className="text-left font-medium leading-none text-gray-800 ">
                          Image<span className="text-red-600">*</span>
                        </p>
                        <input
                          type="file"
                          className="w-full p-3 mt-4 border border-gray-300 rounded outline-none focus:bg-gray-50"
                          name="image"
                          onChange={(e) => handleImageChange(e)}
                        />
                        {/* <p className="mt-3 text-xs leading-3 text-gray-600">
                                                Set a simple and precise name
                                            </p> */}
                      </div>
                      <div className="col-sm-12 mb-6">
                        <p className="text-left font-medium leading-none text-gray-800 my-3">
                          Description<span className="text-red-600">*</span>
                        </p>
                        <ReactQuill
                          className="quill-editor my-5 "
                          value={blogDetail?.description}
                          placeholder={"Description*"}
                          onChange={(e) =>
                            setBlogDetail({ ...blogDetail, description: e })
                          }
                        />
                      </div>
                      <div className="col-sm-6 relative zIndex-1000">
                        <select
                          className=" w-full p-3 cursor-pointer border border-gray-300 rounded outline-none focus:bg-gray-50"
                          name="parentId"
                          ref={selectRef}
                          onChange={(e) => {
                            if (
                              !_.some(selectedCategories, (c) => {
                                return c == e.target.value;
                              })
                            ) {
                              setSelectedCategories((prev) => [
                                ...prev,
                                e.target.value,
                              ]);
                            } else {
                              toast.warning("Category already included.");
                            }
                          }}
                        >
                          <option value="" selected>
                            Select Category
                          </option>
                          {_.map(category, (item, index) => {
                            return (
                              <option key={index} value={item.guid}>
                                {item.key}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="text-left col-sm-6 ">
                        {_.map(selectedCategories, (item, index) => {
                          const oneCategory = category.find((cat) => {
                            return item == cat.guid;
                          });
                          return (
                            <CategoriesComp
                              category={oneCategory}
                              handleRemove={handleRemove}
                            />
                          );
                        })}
                      </div>

                      {/* <div>
                                            <p className="text-base font-medium leading-none text-gray-800">
                                                Amount
                                            </p>
                                            <input
                                                type="number"
                                                className="w-full p-3 mt-4 border border-gray-300 rounded outline-none focus:bg-gray-50"
                                                value={donar.amount}
                                                name="amount"
                                                onChange={(e) => handleChange(e)}
                                            />
                                            {/* <p className="mt-3 text-xs leading-[15px] text-gray-600">
                                                Set Slug that is related to the Post
                                            </p>
                                        </div> */}
                    </div>
                  </div>

                  <hr className="h-[1px] bg-gray-100 my-3" />

                  <div className="flex flex-col flex-wrap items-center justify-center w-full px-7 lg:flex-row lg:justify-end md:justify-end gap-x-4 gap-y-4">
                    <button
                      type="submit"
                      className="bg-[#452a72] rounded  border border-[#452a72] transform duration-300 ease-in-out text-sm font-medium px-6 py-4 text-white  lg:max-w-[144px] w-full  "
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default BlogForm;
