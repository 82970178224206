import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";

import TextEditor from "../Pages/Text";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { handleUnAuthorized } from "../hook/handleUnauthorized";
import ReactQuill from "react-quill";
import "../generosity/css/AddBlog.css";
import AddCat from "./Components/AddCat";
import _ from "lodash";
import BlogForm from "./BlogForm";

function EditBlog() {
  const { blogGuid } = useParams();
  const [blogDetail, setBlogDetail] = useState(null);
  useEffect(() => {
    if (blogGuid) {
      async function fetchData() {
        try {
          const data = await axios.get(
            process.env.REACT_APP_API_URL + "/api/blog/" + blogGuid
          );
          if (data.data.success) {
            setBlogDetail({ ...data?.data?.data });
          }
        } catch (error) {
          console.log(error);
        }
      }
      fetchData();
    }
  }, [blogGuid]);

  return (
    <>{blogDetail && <BlogForm isEdit={true} editBlogDetail={blogDetail} />}</>
  );
}

export default EditBlog;
