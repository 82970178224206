import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Input, TextField } from "@mui/material";
import { Select, Option } from "@material-tailwind/react";
import axios from "axios";
import { toast } from "react-toastify";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, color: "#452a72" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#452a72",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddCat({ open, setOpen }) {
  const [category, setCategory] = useState([]);
  const [selectedParent, setSelectedParent] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    parentId: "",
  });
  const handleChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setFormData({
      ...formData,
      [key]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(process.env.REACT_APP_API_URL + "/api/category", {
        ...formData,
      });
      toast.success("Category created.....");
      setOpen(false);
    } catch (error) {
      toast.error("something went wrong..");
      setOpen(false);
    }
  };

  const handleParentSelect = (e) => {
    setSelectedParent(e.target.value);
    console.log("Selected Parent:", e.target.value);
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const data = await axios.get(
          process.env.REACT_APP_API_URL + "/api/category"
        );

        setCategory(data.data.cate);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      <BootstrapDialog
        sx={{ zIndex: "11000" }}
        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => setOpen(false)}
        >
          Add Category
        </BootstrapDialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent dividers className="grid grid-row-1 gap-6">
            <div>
              <input
                required
                placeholder="Category Name"
                type="text"
                name="title"
                className="w-full p-3  border border-gray-300 rounded outline-none focus:bg-gray-50"
                value={formData.title}
                onChange={handleChange}
              />
              <div className="w-72 mt-5 relative zIndex-1000">
                <select
                  className="w-full p-3 cursor-pointer border border-gray-300 rounded outline-none focus:bg-gray-50"
                  value={formData.parentId}
                  name="parentId"
                  onChange={handleChange}
                >
                  <option value="" selected>
                    Select Parent
                  </option>
                  {category.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.key}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <button
              type="submit"
              className="bg-[#452a72] font-medium transition duration-150 ease-in-out  rounded text-white  px-6 py-2 text-sm border border-[#452a72]  focus:outline-none"
            >
              Add
            </button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}
