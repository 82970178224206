import BlogForm from "./BlogForm";
function AddBlog() {
  return (
    <>
      <BlogForm isEdit={false} />
    </>
  );
}

export default AddBlog;
