import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { TrashIcon, PencilIcon } from "@heroicons/react/24/solid";
import { IconButton, Tooltip } from "@material-tailwind/react";
import { useNavigate } from "react-router";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../App.css";
// import ApplyScheme from './ApplyScheme';
import {
  DatePicker,
  InputSearch,
  SchemeForDropDown,
  SchemeNameDropDown,
  handleUnAuthorized,
} from "../../hook/handleUnauthorized";
import jwtDecode from "jwt-decode";
import DonateScheme from "./DonateScheme";
import PayNowModal from "./PayNowModal";

const ScholarshipTable = () => {
  const [scheme, setScheme] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [item, setItem] = useState({});
  const [user, setUser] = useState({});
  const [search, setSearch] = useState({});
  const [payment, setPayment] = useState({});
  const [payNowModal, setPayNowModal] = useState(false);
  const [noOfStudent, setNoOfStudent] = useState([]);
  const [duration, setDuration] = useState([]);
  const [totalAmount, setTotalAmount] = useState([]);
  const navigate = useNavigate();

  // const handleDltPost = () => { };

  // const handleEditPost = (id) => {
  //     navigate(`${id}/edit`)

  // };
  function getIntAccToDuration(d) {
    if (d == "1 month") {
      return parseInt(1);
    } else if (d == "6 month") {
      return parseInt(6);
    } else if (d == "12 month") {
      return parseInt(12);
    }
    return;
  }
  const handleSubmit = async (s, setProgress) => {
    const user = JSON.parse(localStorage.getItem("user"));
    //Data to send for making data Donation
    if (user) {
      const payloadForDonationDetail = {
        totalAmount: s.totalAmount,
        duration: s.duration,
        noOfStudent: s.noOfStudent,
        amount: s.amount,
        schemeId: s.id,
        donarId: user.id,
        email: user.email,
        donarName: user.name,
        phone: user.mobile,
      };

      makePayment(payloadForDonationDetail, setProgress);
      return;
    }
    setProgress("Try Again..");
    toast.error("Data not Found.....");
  };

  const makePayment = async (orderDetails, setProgress) => {
    console.log("order Detail", orderDetails);
    const key = process.env.REACT_APP_TEST_RAZORPAY_KEY_ID;
    let razorPayOrder;
    let razor_order_id = null;
    let donationGuid;
    try {
      razorPayOrder = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/razorpay/scheme/order`,
        orderDetails
      );
      setProgress("Order Created..");
      razor_order_id = razorPayOrder.data?.order_id;
      donationGuid = razorPayOrder.data?.transactionDetail?.id;
    } catch (error) {
      toast.error(error.toString());
      setProgress("Oops.. Try again");
      return;
    }
    setProgress("Payment Initiated.. ");
    const options = {
      key: key,
      name: orderDetails?.donarName,
      currency: "INR",
      order_id: razor_order_id || 0,
      handler: async function (response) {
        console.log("response from handler success", response);
        try {
          await axios.patch(
            `${process.env.REACT_APP_API_URL}/api/donationDetail/${donationGuid}`,
            {
              paymentReferenceId: response.razorpay_payment_id,
              status: "success",
            }
          );
          toast.success("payment completed successfully....");
          setProgress("Payment Successfully");
        } catch (error) {
          console.log(error);
        }
      },
      prefill: {
        name: orderDetails?.first_name,
        email: orderDetails?.email,
        contact: orderDetails?.phone,
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();

    paymentObject.on("payment.failed", async function (response) {
      try {
        await axios.patch(
          `${process.env.REACT_APP_API_URL}/api/donationDetail/${donationGuid}`,
          {
            status: "failed",
          }
        );
        setProgress("Captured Failed");
      } catch (error) {
        setProgress("Failed");
      }
      alert("Payment failed. Please try again. Contact support for help");
    });
  };
  const SchemeList = async () => {
    const token = localStorage.getItem("token") || "";
    if (token) {
      let query = {
        schemeNames: search.schemeName,
        endDate: search.endDate,
        startDate: search.startDate,
        keyword: search.keyword,
        schemeFor: search.schemeFor,
      };
      const data = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/scheme-list`,
        { params: query, headers: { Authorization: token } }
      );
      if (data.data.success) {
        const arr = [];
        const arr2 = [];
        data.data.data.scheme?.map((data) => {
          if (data?.noOfStudent) arr.push(data?.noOfStudent);
          else arr.push(1);
        });
        data.data.data.scheme?.map((data) => {
          if (data?.duration) arr2.push(data?.duration);
          else arr2.push("1 month");
        });
        setNoOfStudent(arr);
        setDuration(arr2);
        console.log("arrow2", arr2);
        setTotalAmount(
          data.data.data.scheme?.map((data, index) => {
            return {
              amount:
                parseInt(data.amount) *
                parseInt(arr[index]) *
                getIntAccToDuration(arr2[index]),
              real: data.amount,
            };
          })
        );
        setScheme(data.data.data.scheme);
      } else {
        handleUnAuthorized(data.data.msg, navigate);
      }
    } else {
      navigate("/login");
    }
  };

  // const handleScheme = (item) => {
  //   let user = localStorage.getItem("user") || "";
  //   if (user) {
  //     user = JSON.parse(user);
  //     setPayNowModal(true);
  //     setItem({ ...item, studentId: user.id, studentName: user.name });
  //   } else {
  //     navigate("/login");
  //   }
  // };

  const handleFunction = (type, value, index) => {
    if (type === "student") {
      if (value <= 0) return;
      const arr = [...noOfStudent];
      const arr2 = [...totalAmount];
      arr[index] = value;
      console.log("duration d", getIntAccToDuration(duration[index]));

      arr2[index].amount =
        arr2[index].real *
        parseInt(value) *
        getIntAccToDuration(duration[index]);
      setTotalAmount(arr2);
      setNoOfStudent(arr);
    } else {
      const arr = [...duration];
      const arr2 = [...totalAmount];
      if (value == "1 month") {
        arr2[index].amount = arr2[index].real * 1 * noOfStudent[index];
      }
      if (value == "6 month") {
        arr2[index].amount = arr2[index].real * 6 * noOfStudent[index];
      }
      if (value == "12 month") {
        arr2[index].amount = arr2[index].real * 12 * noOfStudent[index];
      }
      arr[index] = value;
      setDuration(arr);
      setTotalAmount(arr2);
    }
  };

  // const handleDonate = async (item, index) => {
  //   const token = localStorage.getItem("token") || "";
  //   let amount = 0;
  //   amount = totalAmount[index].amount2 * noOfStudent[index];
  //   if (duration[index] === "6 month") amount = amount * 6;
  //   if (duration[index] === "12 month") amount = amount * 12;
  //   if (+amount != +totalAmount[index].amount) window.location.reload();
  //   if (token) {
  //     const payload = {
  //       paymentReferenceId: "#" + Math.floor(Math.random() * 1000000008),
  //       schemeId: item.id,
  //       totalAmount: totalAmount[index].amount,
  //       amount: totalAmount[index].amount2,
  //     };
  //     if (scheme[index]?.noOfStudent)
  //       payload.noOfStudent = scheme[index]?.noOfStudent;
  //     if (scheme[index]?.duration) payload.duration = scheme[index]?.duration;
  //     setPayment(payload);
  //     setPayNowModal(true);
  //   } else {
  //     navigate("/login");
  //   }
  // };

  useEffect(() => {
    SchemeList();
    const token = localStorage.getItem("token");
    if (token) {
      setUser(jwtDecode(token));
    } else {
      navigate("/login");
    }
  }, [search]);
  const RowComponent = ({ post, index }) => {
    const [progress, setProgress] = useState("Pay Now");
    return (
      <tr
        key={post.id}
        className="h-20 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100"
      >
        <td className="pl-4">
          <p className="text-sm font-medium leading-none text-gray-800">
            {index + 1}
          </p>
        </td>
        <td className="pl-4">
          <p className="text-sm font-medium leading-none text-gray-800">
            {post.name}
          </p>
        </td>
        <td className="pl-12">
          <p className="text-sm font-medium leading-none text-gray-800">
            {post.className}
          </p>
        </td>
        <td className="pl-12">
          <p className="text-sm font-medium leading-none text-gray-800">
            {post.amount}
          </p>
        </td>
        <td className="pl-12">
          <p className="text-sm font-medium leading-none text-gray-800">
            <input
              type="number"
              disabled={post?.type === "generalDonation" && post?.noOfStudent}
              value={noOfStudent[index]}
              onChange={(e) => handleFunction("student", e.target.value, index)}
              placeholder="No. of student"
              className="px-4 py-0 h-[43px] w-[130px] mt-4 ml-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#452a72] focus:border-[#452a72]"
            />
          </p>
        </td>
        <td className="pl-12">
          <p className="text-sm font-medium leading-none text-gray-800">
            <select
              placeholder="Select City"
              disabled={post?.type === "generalDonation" && post?.noOfStudent}
              onChange={(e) =>
                handleFunction("duration", e.target.value, index)
              }
              className="px-4 py-0 h-[43px] w-[130px] mt-4 ml-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#452a72] focus:border-[#452a72]"
            >
              <option value="" disabled selected>
                Select Duration
              </option>
              {["1 month", "6 month", "12 month"]?.map((data, index1) => (
                <option
                  key={index}
                  value={data}
                  selected={duration[index] === data}
                >
                  {data}
                </option>
              ))}
            </select>
          </p>
        </td>

        <td className="pl-12">
          <p className="text-sm font-medium leading-none text-gray-800">
            {totalAmount[index].amount}
          </p>
        </td>
        <td className="px-5 2xl:px-0">
          {/* {user.type === 'stident' ? <button style={{ padding: '10px', width: '150px', marginLeft: '50px' }} onClick={() => handleScheme(post)}>
                        Apply
                    </button> :  */}
          <button
            className="bg-[#452a72] font-medium transition duration-150 ease-in-out  rounded text-white  px-6 py-2 text-sm border border-[#452a72] focus:outline-none"
            style={{
              padding: "10px",
              width: "150px",
              height: "50px",
              marginLeft: "50px",
            }}
            //   onClick={() => handleDonate(post, index)}
            onClick={() =>
              handleSubmit(
                {
                  ...post,
                  totalAmount: totalAmount[index].amount,
                  duration: duration[index],
                  noOfStudent: noOfStudent[index],
                },
                setProgress
              )
            }
            // disabled={progress=="Pay Now" || progress=="Oops.. Try again"}
          >
            {progress}
          </button>
          {/* } */}
        </td>
      </tr>
    );
  };
  return (
    <>
      {payNowModal && (
        <PayNowModal
          open={payNowModal}
          setOpen={setPayNowModal}
          item={payment}
        />
      )}
      <div className="bg-white shadow px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">
        <div className="mb-4 flex justify-end">
          <DatePicker search={search} setSearch={setSearch} />
          <SchemeNameDropDown search={search} setSearch={setSearch} />
          {/* <SchemeForDropDown search={search} setSearch={setSearch} /> */}
          <InputSearch
            search={search}
            setSearch={setSearch}
            msg={"Search..."}
          />
        </div>
        <table className="w-full whitespace-nowrap">
          <thead>
            <tr className="h-16 w-full text-sm leading-none text-gray-800">
              <th className="font-normal text-left pl-4">S.No.</th>
              <th className="font-normal text-left pl-4">Scheme Name</th>
              <th className="font-normal text-left pl-12">Scheme For</th>
              <th className="font-normal text-left pl-12">
                Amount ( per Student/ Month.)
              </th>
              <th className="font-normal text-left pl-12">No. of Students</th>
              <th className="font-normal text-left pl-12">Duration</th>
              <th className="font-normal text-left pl-12">Total Amount</th>
              <th className="font-normal text-left pl-12">Pay Now</th>
            </tr>
          </thead>
          <tbody className="w-full">
            {scheme &&
              scheme?.length > 0 &&
              scheme?.map((post, index) => {
                return <RowComponent post={post} index={index} key={post.id} />;
              })}
          </tbody>
        </table>
        {scheme.length === 0 && (
          <div className="border p-2" style={{ textAlign: "center" }}>
            No Data Found.
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default ScholarshipTable;
